import React, { useContext, useEffect, useState,useRef } from 'react';
import { useSearchParams, useParams,useNavigate,useLocation } from 'react-router-dom';
import { Type,Link,ButtonLink,ScreenReader,Button } from '@barclays/blueprint-react';
import { SPContext } from './context/SPContext';
import _ from 'lodash';
import { OfferType } from './types/ShoppingPageDataTypes';
import RepresentativeContent from './components/RepresentativeContent';
import CloudItUpload from './components/CloudItUpload';
import Footer from './components/Footer';
import axios from './hooks/axiosAdaptor';

const showPageHeader = () => {
  let channelId = window.localStorage && window.localStorage.getItem('channelId');
  let bmbHeaderSwtich = window.localStorage && window.localStorage.getItem('bmbHeaderSwitch');
  return (channelId==="1" && bmbHeaderSwtich==="ON")?false:true;
}

export default function ProductDetails() {
  const autoFocusRef = useRef<HTMLDivElement>(null);
  let { productCode } = useParams();
  const [offerData, setOfferData] = useState<OfferType>({} as OfferType);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  let location = useLocation();
  const {meta, data,updateTaggingData } = useContext(SPContext);
    useEffect(() => {
    if (autoFocusRef.current !== null) {
      autoFocusRef.current.focus();
    }
  }, [])
  useEffect(() => {
    if(data.offers){
      let offer = data.offers.filter((offer) => offer.productCode === productCode)?.[0];
      setOfferData(offer);
      if(window.localStorage){
        window.localStorage.setItem("spPreApproved",''+offer.spPreApproved);
      }
      try{
        updateTaggingData({
          'page_level3': 'shopping:'+ searchParams.get('category')+ ":" + offer.productCode,
          'event_product': offer.productCode,
          'page_version': offer.spProdDesc,
          'event_type': 'screenload'
        });
      }
      catch(e){
        console.warn("Tagging called failed");
      }
    }
  }, [data.offers, productCode,updateTaggingData,searchParams]);
  const showProductList = () => {
    navigate('/shopping-page' +location.search);
    // window.history.back();
  }
  const invokeAmazonURL = (eligibilityUrl: string,target: string|null) => {
    if(eligibilityUrl.includes("amazon.co.uk")){
      updateTaggingData({
        'event_label': 'AmazonCheckEligNApply',
        'event_method': 'button',
        'event_type': 'click',
      });
      axios.post('/golf/service/shopping-page/updateRtm',{
        "eventId": "logAmazonRedirect"
      })
      .then(() => window.open(eligibilityUrl,target || '_self'))
      .catch(() => 
        axios.get('/golf/service/clear').finally(() => window.open(eligibilityUrl,target || '_self'))
      );
    }
  }
  const invokeEligibilityURL = (eligibilityUrl: string,target: string|null) => {
    updateTaggingData({
      'event_label': 'CheckEligNApply',
      'event_method': 'button',
      'event_type': 'click',
    });
    window.open(eligibilityUrl,target || '_self')
  }
  const invokeApplyURL = (offerData: OfferType) => {
    updateTaggingData({
      'event_label': 'Apply',
      'event_method': 'button',
      'event_type': 'click',
    });
    window.open(window.location.origin + `/uka-client-index.html#card-application/pc=${offerData.productCode}&mc=${offerData.originCode}&tc=${offerData.trackingCode}&sn=1`,'_self')
  }
  const showSummaryBox = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    navigate(location.pathname + '/summaryBox' +location.search);
    updateTaggingData({
      'event_label': 'SummaryBox',
      'event_method': 'link',
      'event_type': 'click',
    });
  }
  return (
    <>
      {offerData && <div id='productDetailsPage' className='header'>
        {showPageHeader() && <div className='header'>
          <div ref={autoFocusRef} tabIndex={-1}>
            <ScreenReader>You are currently on {offerData.spProdDesc} Details Page.</ScreenReader>
          </div>
          <Type element='h1' alignment='center' size='md' className='txt'>{_.capitalize(_.startCase(searchParams.get('category')!))}</Type>
        </div>}
        <div className='product-intro'>
          <div className='product-bg'></div>
          <div className='product-info'>
            <img src={'../images/' + (offerData.cardImage)} className='card-img' alt={offerData.spProdDesc} />
            <Type element='h2' size='xl' className='h3 p-name'>{offerData.spProdDesc}</Type>
            <div className='p-badges'>
              {offerData.topOffer && <><img src={'../images/topoffer-badge.png'} alt='top offer' aria-hidden={true}/><ScreenReader>topoffer badge</ScreenReader></>}
            </div>
          </div>
        </div>
        {offerData.spPreApproved &&
        <div className='section-wrapper pre-appr'>
        <div className='section-content'>
          <img src={'../images/preapproved-tick.png'} className='o-icon card-img' alt='pre approved tick' aria-hidden={true}/>
          <Type element='span' size='md'>{meta.PreApprovedText.placeholder}</Type>
          <Type element='p' className='txt-sm'>{meta.PreApprovedText.label}<sup>1</sup></Type>
        </div></div>}

        <div className='section-wrapper'>
          <div className='section-content products-bullets'>
            <Type element='h2' size='xl' alignment='center' marginBottom={true}>{meta.mainBulletsTitle.label}</Type>
            {offerData?.mainBulletPoints?.map((item:string,key) => (
              <div className='bullet-item' key={key}>
                <Type element='h3' size='md'>{item.split("|")[0]}</Type>
                <p className='txt-sm'>{item.split("|")[1]}</p>
              </div>
            ))}
          </div>
        </div>

        <div className='section-wrapper'>
          <div className='section-content'>
            <Type element='h2' size='xl' alignment='center' marginBottom={true}>{meta.RepresentativeExampleText.label}</Type>
            <Type element='h3' size='md' className='rep-h3' marginBottom={true}>{meta.RepresentativeExampleText.tooltip}</Type>
            <RepresentativeContent offerData={offerData} meta={meta} marginBottom='sm' className='txt'/>
            {((offerData.eligibilityUrl && !offerData.eligibilityUrl.includes("amazon.co.uk"))|| !offerData.eligibilityUrl) && <>
              <Link href='' className='summary-link txt' onClick={(e)=>showSummaryBox(e)}>{meta.summaryBox.label}</Link>
              {offerData.clouditSwitch === 'Y' && <CloudItUpload meta={meta} productCode={offerData.productCode}/>}
            </>}
            <Type element='p' className='txt-sm'>
              {offerData.spPreApproved && meta.paNoteText.label}
              {!offerData.spPreApproved && meta.noteText.label}
              {!offerData.spPreApproved && offerData.spInstantBTFlag
              && " " + meta.btNoteText.label}
            </Type>
            
            <div className='btn-container'>
              {((data.channelId === 1 || data.channelId === 2) && data.journeyType === "NPS")?
              ((offerData.eligibilityUrl && offerData.eligibilityUrl.includes("amazon.co.uk"))?
              <div className='eligibility-btn'>
                <ButtonLink fullWidth variant='primary' onClick={()=>invokeAmazonURL(offerData.eligibilityUrl,offerData.targetValue )} role='button'>{offerData.spPreApproved?meta.confirmEligibilityButton.label:meta.checkEligibilityButton.label}</ButtonLink>
              </div>:
              <div className='eligibility-btn'>
                <ButtonLink fullWidth variant='primary' onClick={()=>invokeEligibilityURL(offerData.eligibilityUrl,offerData.targetValue )} role='button'>{offerData.spPreApproved?meta.confirmEligibilityButton.label:meta.checkEligibilityButton.label}</ButtonLink>
              </div>
              )
              :(<div className='eligibility-btn'>
                <ButtonLink fullWidth variant='primary' onClick={()=>invokeApplyURL(offerData)} role='button'>{meta.ApplyNowButton.label}</ButtonLink>
              </div>)}
              <div className='eligibility-btn'>
                <Button fullWidth onClick={() => showProductList()} role='button'>Back to cards</Button>
              </div>
            </div>
          </div>
        </div>
        {offerData.spPreApproved && 
        <div className='section-wrapper' id="ref1">
          <div className='section-content'>
            <Type element='p' className='txt-sm' marginBottom="xs"><sup>1</sup>{meta.PreApprovedText.tooltip}</Type>
          </div>
        </div>}
        <Footer meta={meta}/>
      </div>}
    </>
  );
}